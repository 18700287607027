import React from 'react';
import PDLStatus from './Components/PDLStatus/PDLStatus';
import PDLLogo from './assets/purolab_white.png';
import PDLFooter from './Components/Footer/PDLFooter';
import StatusProvider from './Context/StatusContext';
import { Container } from 'react-bootstrap';

import './App.css';

function App() {
  return (
    <StatusProvider>
      <header>
        {/* TODO: make the header layout change on small screens so only the PDL logo is visible */}
        <Container fluid className='header d-flex align-items-center justify-content-evenly'>
          {/* pb-3 here roughly visually aligns the image with the text */}
          {/* Max width is based on the Figma design, it might not work on all displays */}
          <img className="logo m-auto pb-3" src={PDLLogo} alt="PDL Logo" style={{ maxWidth: "240px" }} />

          {/* I took a guess here with hiding the text on small screens. We may decide to stack */}
          {/* the layout instead of hiding it in the future. */}
          <h4 className="text-white m-auto text-end fw-bold d-sm-block d-none">System Status Overview</h4>
        </Container>
      </header>

      {/* mb-5 ensures the footer cannot obscure status information */}
      <main className='mb-5'>
        <PDLStatus />
      </main>

      <PDLFooter />
    </StatusProvider>
  );
}
export default App;
