import React from 'react';
import ReactDOM from 'react-dom/client';

// It is important that bootstrap is imported BEFORE App so
// that App CSS takes precedence
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
     <App />
);

